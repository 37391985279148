'use client';

import React from 'react';
import { Layout } from 'antd';
import { Tally } from '@widgets/tally';
import { MkitTour } from '@widgets/mkit-tour';
import { Backdrop } from '@shared/ui/Backdrop';
import { Header } from '@widgets/mediakit/Header';
import { useUserMessages } from './user-messages';
import { Hotkeys } from '@widgets/mediakit/hot-keys';
import { GlobalStyles } from '@shared/ui/GlobalStyles';
import { ChristmasMode } from '@shared/ui/christmas-mode';
import { Background } from '@widgets/mediakit/background';
import { GridLayout } from '@widgets/mediakit/grid-layout';
import { ThemeDrawer } from '@widgets/mediakit/theme-drawer';
import { Profile } from '@widgets/mediakit/MediaKit/Profile';
import { fastAnimation, fastAnimationMS } from '@shared/theme';
import { ComponentsDrawer } from '@widgets/mediakit/components-drawer';
import { BottomActions } from '@widgets/mediakit/MediaKit/Common/Actions';
import {
  useSelector,
  selectHolidayMode,
  selectTallyIsClosed,
  selectBuilderPreviewMode,
} from '@shared/redux';
import {
  BuilderContent,
  BuilderContainer,
  AdditionalContainer,
} from '@shared/ui/content';

let interval: NodeJS.Timeout;

export function MediaKit({
  slug,
  username,
  showSkeleton,
}: {
  slug: string;
  username: string;
  showSkeleton: boolean;
}) {
  useUserMessages();
  const holidayMode = useSelector(selectHolidayMode);
  const tallyIsClosed = useSelector(selectTallyIsClosed);
  const previewMode = useSelector(selectBuilderPreviewMode);

  const [elemsAreAvailable, setElemsAreAvailable] = React.useState(false);

  const [styles, setStyles] = React.useState<React.CSSProperties>({
    opacity: 0,
    minWidth: 360,
    display: 'flex',
    minHeight: '100%',
    background: '#F5F5F5',
    transition: `opacity ${fastAnimation} linear`,
  });

  React.useEffect(() => {
    setTimeout(() => {
      setStyles((prevState) => ({
        ...prevState,
        opacity: 1,
      }));
    }, fastAnimationMS);
  }, []);

  React.useEffect(() => {
    interval = setInterval(() => {
      const addComponentTarget = document.getElementById('addComponentTarget');

      if (addComponentTarget) {
        clearInterval(interval);

        setElemsAreAvailable(true);
      }
    }, 1500);

    return () => {
      clearInterval(interval);
    };
  }, []);

  return (
    <Layout style={styles}>
      <ChristmasMode disabled={!holidayMode} />
      <Header slug={slug} username={username} showSkeleton={showSkeleton} />
      <Hotkeys />
      <Backdrop />
      <Background showSkeleton={showSkeleton} />
      <GlobalStyles />
      <AdditionalContainer className={previewMode}>
        <BuilderContent className={previewMode}>
          <BuilderContainer className={previewMode}>
            <Profile
              slug={slug}
              username={username}
              showSkeleton={showSkeleton}
            />
            <ComponentsDrawer slug={slug} username={username} />
            <ThemeDrawer slug={slug} username={username} />
            <GridLayout showSkeleton={showSkeleton} />
            <BottomActions slug={slug} username={username} />
          </BuilderContainer>
        </BuilderContent>
      </AdditionalContainer>
      {!showSkeleton && !tallyIsClosed && <Tally />}
      {!showSkeleton && elemsAreAvailable && <MkitTour />}
    </Layout>
  );
}
