import { api } from './api';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { transformErrorResponse } from '@shared/utils/transformErrorResponse';

export const instagramCalculatorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    instagramERC: builder.mutation<any, { username: string }>({
      transformErrorResponse,
      query: (body) => ({
        body,
        method: 'POST',
        url: ApiEndpoints.instagramERC,
      }),
    }),
    instagramFFC: builder.mutation<any, { username: string }>({
      transformErrorResponse,
      query: (body) => ({
        body,
        method: 'POST',
        url: ApiEndpoints.instagramFFC,
      }),
    }),
  }),
});

export const { endpoints, useInstagramERCMutation, useInstagramFFCMutation } =
  instagramCalculatorsApi;
