import { api } from './api';
import { ApiEndpoints } from '@shared/constants/apiEndpoints';
import { transformErrorResponse } from '@shared/utils/transformErrorResponse';

export const tiktokCalculatorsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    tiktokERC: builder.mutation<any, { username: string }>({
      transformErrorResponse,
      query: (body) => ({
        body,
        method: 'POST',
        url: ApiEndpoints.tiktokERC,
      }),
    }),
  }),
});

export const { endpoints, useTiktokERCMutation } = tiktokCalculatorsApi;
