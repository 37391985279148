'use client';

import clsx from 'clsx';
import dayjs from 'dayjs';
import Image from 'next/image';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { AppRoutes } from '@shared/constants/appRoutes';
import { validateEmail } from '@shared/utils/validator';
import { useRouter, useSearchParams } from 'next/navigation';
import { Button, Form, Input, Modal, Typography } from 'antd';
import registerPopupBg from '@shared/icons/register-popup.svg';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import styles from './register-modal.module.css';

const popupTimeout = Number(process.env.NEXT_PUBLIC_DISPLAY_POPUP_TIMEOUT);

let timeout: NodeJS.Timeout;

const CloseButton: React.FC<
  DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>
> = ({ className, ...props }) => {
  return (
    <div className={clsx(className, styles.closeButton)} {...props}>
      <svg
        width="24"
        height="24"
        fill="none"
        viewBox="0 0 24 24"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fill="black"
          d="M12 1.5C6.20156 1.5 1.5 6.20156 1.5 12C1.5 17.7984 6.20156 22.5 12 22.5C17.7984 22.5 22.5 17.7984 22.5 12C22.5 6.20156 17.7984 1.5 12 1.5ZM15.8766 15.9891L14.3297 15.982L12 13.2047L9.67266 15.9797L8.12344 15.9867C8.02031 15.9867 7.93594 15.9047 7.93594 15.7992C7.93594 15.7547 7.95234 15.7125 7.98047 15.6773L11.0297 12.0445L7.98047 8.41406C7.95215 8.37971 7.93643 8.3367 7.93594 8.29219C7.93594 8.18906 8.02031 8.10469 8.12344 8.10469L9.67266 8.11172L12 10.8891L14.3273 8.11406L15.8742 8.10703C15.9773 8.10703 16.0617 8.18906 16.0617 8.29453C16.0617 8.33906 16.0453 8.38125 16.0172 8.41641L12.9727 12.0469L16.0195 15.6797C16.0477 15.7148 16.0641 15.757 16.0641 15.8016C16.0641 15.9047 15.9797 15.9891 15.8766 15.9891Z"
        />
      </svg>
    </div>
  );
};

export const PopupPrompting: React.FC<{
  user?: {
    name: string;
    handle: string;
    tiktok?: string;
    youtube?: string;
    vm_verify: number;
    created_at: string;
    instagram?: string;
    default_plan: string;
    country: null | string;
    og_image: null | string;
    profile_photo_path: string;
  };
}> = ({ user }) => {
  const { push } = useRouter();
  const is768 = useBreakpoint(768);
  const postHog = useLocalPostHog();
  const searchParams = useSearchParams();
  const [error, setError] = React.useState('');
  const [open, setOpen] = React.useState(false);
  const [email, handleEmailChange] = React.useState('');

  React.useEffect(() => {
    const unix = localStorage.getItem('monetizeYourSocialMedia');

    if (unix) {
      const diffInMinutes = dayjs().diff(dayjs(Number(unix) * 1000), 'minutes');

      if (diffInMinutes < 1440) {
        return;
      }
    }

    if (timeout) {
      clearTimeout(timeout);
    }

    timeout = setTimeout(() => {
      setOpen(true);

      localStorage.setItem('monetizeYourSocialMedia', `${dayjs().unix()}`);
    }, 3000);

    return () => clearTimeout(timeout);
  }, []);

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');

    handleEmailChange(e.target.value);
  };

  if (!user) {
    return null;
  }

  const isPaid = user.default_plan !== 'free';

  if (isPaid) {
    return null;
  }

  if (Number.isNaN(popupTimeout)) {
    return null;
  }

  const diffInMinutes = dayjs().diff(dayjs(user.created_at), 'minutes');

  if (diffInMinutes < popupTimeout) {
    return null;
  }

  const onCreateMediaKit = () => {
    if (!validateEmail(email)) {
      setError('Please enter a valid email address.');

      return;
    }

    const params = searchParams.toString();

    try {
      postHog?.capture(PosthogEvents.RegisterFromPopup, {
        email,
      });
    } catch (e) {
      console.log(e);
    }

    sessionStorage.setItem('email', email);

    if (params) {
      push(`${AppRoutes.registerStep1}?${params}&email=${email}`);
    } else {
      push(`${AppRoutes.registerStep1}?email=${email}`);
    }
  };

  const onClose = () => {
    try {
      postHog?.capture(PosthogEvents.CloseRegisterFromPopup, {
        email: email || '',
      });
    } catch (e) {
      console.log(e);
    }

    setOpen(false);
  };

  const afterClose = () => {
    try {
      postHog?.capture(PosthogEvents.CloseRegisterFromPopup, {
        email: email || '',
      });
    } catch (e) {
      console.log(e);
    }
  };

  if (!open) {
    return null;
  }

  return (
    <>
      <div
        style={is768 ? { display: 'none' } : {}}
        className={clsx(
          styles.customModalWrapper,
          open ? styles.customModalIsOpen : styles.customModalIsClosed,
        )}
      >
        <CloseButton onClick={onClose} />

        <div className={styles.customModalBody}>
          <div className={styles.imageWrapper}>
            <div className={styles.imageMask} />
            <Image width={361} height={200} src={registerPopupBg} alt="" />
          </div>

          <div className={styles.content}>
            <Typography.Text
              strong
              style={{ fontSize: 20, lineHeight: '28px' }}
            >
              Monetize Your Social Media
            </Typography.Text>

            <div className={styles.formWrapper}>
              <Form.Item
                help={error}
                validateStatus={!!error ? 'error' : undefined}
              >
                <Input
                  type="email"
                  size="large"
                  value={email}
                  onChange={onChange}
                  style={{ width: 300 }}
                  placeholder="Email Address"
                />
              </Form.Item>

              <Button
                size="large"
                type="primary"
                onClick={onCreateMediaKit}
                style={{ width: 140, transform: 'translateY(-8px)' }}
              >
                Create Media Kit
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        centered
        closable
        footer={null}
        closeIcon={<></>}
        width="fit-content"
        maskClosable={false}
        afterClose={afterClose}
        open={is768 ? open : false}
        onClose={() => setOpen(false)}
        onCancel={() => setOpen(false)}
        styles={{ content: { padding: 0, overflow: 'hidden' } }}
      >
        <div className={styles.customModalWrapper}>
          <CloseButton onClick={onClose} />

          <div className={styles.customModalBody}>
            <div className={styles.imageWrapper}>
              <div className={styles.imageMask} />
              <Image width={361} height={200} src={registerPopupBg} alt="" />
            </div>

            <div className={styles.content}>
              <Typography.Text
                strong
                style={{ fontSize: 20, lineHeight: '28px' }}
              >
                Monetize Your Social Media
              </Typography.Text>

              <div className={styles.formWrapper}>
                <Form.Item
                  help={error}
                  validateStatus={!!error ? 'error' : undefined}
                >
                  <Input
                    type="email"
                    size="large"
                    value={email}
                    onChange={onChange}
                    style={{ width: 300 }}
                    placeholder="Email Address"
                  />
                </Form.Item>

                <Button
                  size="large"
                  type="primary"
                  onClick={onCreateMediaKit}
                  style={{ width: 140, transform: 'translateY(-8px)' }}
                >
                  Create Media Kit
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};
