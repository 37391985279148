'use client';

import React from 'react';
import { Snowfall } from 'react-snowfall';

const styles: React.CSSProperties = {
  zIndex: 1,
  width: '100vw',
  height: '100vh',
  position: 'fixed',
};

export const ChristmasMode: React.FC<{ disabled: boolean }> = ({
  disabled,
}) => {
  if (disabled) return null;

  return (
    <Snowfall
      style={styles}
      radius={[1, 4]}
      wind={[-0.5, 2]}
      speed={[0.5, 1]}
      snowflakeCount={75}
      opacity={[0.1, 0.2]}
      rotationSpeed={[-0.5, 0.5]}
    />
  );
};
