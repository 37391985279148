import { createSlice } from '@reduxjs/toolkit';
import { FiniteStates } from '@shared/constants/finiteStates';
import { endpoints } from '@shared/services/tiktok-calculators';
import { endpoints as authEndpoints } from '@shared/services/auth';

const initialState: {
  engagement: {
    data: any;
    status: FiniteStates;
  };
} = {
  engagement: {
    data: null,
    status: FiniteStates.IDLE,
  },
};

const { tiktokERC } = endpoints;
const { logout } = authEndpoints;

export const tiktokCalculatorsSlice = createSlice({
  name: 'tiktokCalculators',
  initialState,
  reducers: {
    resetTikTokEngagement: (state) => {
      state.engagement.data = null;
      state.engagement.status = FiniteStates.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(tiktokERC.matchPending, (state) => {
        state.engagement.status = FiniteStates.PENDING;
      })
      .addMatcher(tiktokERC.matchRejected, (state, action) => {
        if (action.error.name === 'AbortError') {
          state.engagement.data = null;
          state.engagement.status = FiniteStates.IDLE;
        } else {
          state.engagement.status = FiniteStates.FAILED;
        }
      })
      .addMatcher(tiktokERC.matchFulfilled, (state, action) => {
        if (!action.payload.data) {
          state.engagement.status = FiniteStates.FAILED;
          return;
        }

        if (
          Array.isArray(action.payload.data) &&
          action.payload.data.length === 0
        ) {
          state.engagement.status = FiniteStates.FAILED;
          return;
        }

        state.engagement.data = action.payload.data;
        state.engagement.status = FiniteStates.SUCCEEDED;
      })
      .addMatcher(logout.matchFulfilled, () => {
        return { ...initialState, access_token: null };
      });
  },
});

export const { resetTikTokEngagement } = tiktokCalculatorsSlice.actions;
