import { createSlice } from '@reduxjs/toolkit';
import { Calculators } from '@shared/types/calculators';
import { FiniteStates } from '@shared/constants/finiteStates';
import { endpoints } from '@shared/services/instagram-calculators';
import { endpoints as authEndpoints } from '@shared/services/auth';

const initialState: Calculators.SliceState = {
  engagement: {
    data: null,
    status: FiniteStates.IDLE,
  },
  fakeFollowers: {
    data: null,
    status: FiniteStates.IDLE,
  },
};

const { logout } = authEndpoints;

const { instagramERC, instagramFFC } = endpoints;

export const instagramCalculatorsSlice = createSlice({
  name: 'instagramCalculatorsSlice',
  initialState,
  reducers: {
    resetInstagramEngagement: (state) => {
      state.engagement.data = null;
      state.engagement.status = FiniteStates.IDLE;
    },
    resetInstagramFakeFollowers: (state) => {
      state.fakeFollowers.data = null;
      state.fakeFollowers.status = FiniteStates.IDLE;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(instagramERC.matchPending, (state) => {
        state.engagement.status = FiniteStates.PENDING;
      })
      .addMatcher(instagramERC.matchRejected, (state, action) => {
        if (action.error.name === 'AbortError') {
          state.engagement.data = null;
          state.engagement.status = FiniteStates.IDLE;
        } else {
          state.engagement.status = FiniteStates.FAILED;
        }
      })
      .addMatcher(instagramERC.matchFulfilled, (state, action) => {
        if (!action.payload.data) {
          state.engagement.status = FiniteStates.FAILED;
          return;
        }

        if (
          Array.isArray(action.payload.data) &&
          action.payload.data.length === 0
        ) {
          state.engagement.status = FiniteStates.FAILED;
          return;
        }

        state.engagement.data = action.payload.data;
        state.engagement.status = FiniteStates.SUCCEEDED;
      })

      .addMatcher(instagramFFC.matchPending, (state) => {
        state.fakeFollowers.status = FiniteStates.PENDING;
      })
      .addMatcher(instagramFFC.matchRejected, (state, action) => {
        if (action.error.name === 'AbortError') {
          state.fakeFollowers.data = null;
          state.fakeFollowers.status = FiniteStates.IDLE;
        } else {
          state.fakeFollowers.status = FiniteStates.FAILED;
        }
      })
      .addMatcher(instagramFFC.matchFulfilled, (state, action) => {
        if (!action.payload.data) {
          state.fakeFollowers.status = FiniteStates.FAILED;
          return;
        }

        if (
          Array.isArray(action.payload.data) &&
          action.payload.data.length === 0
        ) {
          state.fakeFollowers.status = FiniteStates.FAILED;
          return;
        }

        state.fakeFollowers.data = action.payload.data;
        state.fakeFollowers.status = FiniteStates.SUCCEEDED;
      })

      .addMatcher(logout.matchFulfilled, () => {
        return { ...initialState, access_token: null };
      });
  },
});

export const { resetInstagramEngagement, resetInstagramFakeFollowers } =
  instagramCalculatorsSlice.actions;
