import type { ReduxState } from '@shared/redux';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const selectTikTokCalculatorsSelf = (state: ReduxState) =>
  state.tiktokCalculators;

export const selectTikTokEngagement = createDraftSafeSelector(
  selectTikTokCalculatorsSelf,
  (state) => state.engagement,
);

export const selectTikTokEngagementData = createDraftSafeSelector(
  selectTikTokEngagement,
  (state) => state.data,
);

export const selectTikTokEngagementStatus = createDraftSafeSelector(
  selectTikTokEngagement,
  (state) => state.status,
);
