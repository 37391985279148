import React from 'react';
import styled from '@emotion/styled';
import { prefixCls } from '@shared/theme';
import { MkitDrawer } from '@shared/ui/MkitDrawer';
import useBreakpoint from '@shared/hooks/useBreakpoint';
import { useLocalPostHog } from '@shared/hooks/useLocalPostHog';
import { PosthogEvents } from '@shared/constants/posthog-events';
import { LinkOutlined, ShareAltOutlined } from '@ant-design/icons';
import { MediaKitStatus } from '@shared/constants/media-kit-status';
import {
  Form,
  Select,
  Button,
  Popover,
  Skeleton,
  Typography,
  DrawerProps,
} from 'antd';
import {
  useDispatch,
  useSelector,
  onOpenShareModal,
  selectDrawerTheme,
  onCloseShareModal,
  selectCurrentMediakit,
  selectShareModalIsOpen,
} from '@shared/redux';
import {
  useEnableMutation,
  useDisableMutation,
} from '@shared/services/mediakit';

const StyledText = styled(Typography.Text)(() => ({
  [`&.${prefixCls}-typography`]: {
    display: 'flex',
    position: 'relative',
    [`& > .${prefixCls}-typography-copy`]: {
      top: 0,
      left: 0,
      bottom: 0,
      margin: 0,
      width: '100%',
      alignItems: 'center',
      position: 'absolute',
      display: 'flex !important',
      '& > span': {
        left: 16,
        position: 'relative',
      },
    },
  },
  '& .anticon': {
    transition: 'all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1)',
  },
  '& .anticon.anticon-copy': {
    display: 'none',
  },
  '& .anticon.anticon-check': {
    display: 'none',
  },
}));

const drawerStyles: DrawerProps['styles'] = {
  body: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  mask: { backgroundColor: 'transparent' },
};

const ButtonSkeleton = () => {
  const is768 = useBreakpoint(768);

  const style = {
    padding: 0,
    display: 'flex',
    alignItems: 'center',
    width: is768 ? 40 : 98,
    minWidth: is768 ? 'unset' : void 0,
  };

  return <Skeleton.Button active size="large" style={style} />;
};

const options = [
  {
    label: 'Anyone with the link can view',
    value: MediaKitStatus.PUBLIC,
  },
  {
    label: 'Private Media Kit',
    value: MediaKitStatus.DISABLED,
  },
];

export const ShareButton: React.FC<{
  slug: string;
  username: string;
  scrolled?: boolean;
  showSkeleton?: boolean;
}> = ({ slug, scrolled, showSkeleton }) => {
  const dispatch = useDispatch();
  const posthog = useLocalPostHog();
  const matches = useBreakpoint(768);

  const [enable, enableRes] = useEnableMutation();
  const [disable, disableRes] = useDisableMutation();

  const [value, setValue] = React.useState('');

  const drawerTheme = useSelector(selectDrawerTheme);
  const mediakit = useSelector(selectCurrentMediakit);
  const modalIsOpen = useSelector(selectShareModalIsOpen);

  const isPublic = mediakit.profile.status === MediaKitStatus.PUBLIC;

  React.useEffect(() => cleanCopyId, []);

  React.useEffect(() => {
    if (typeof window === 'object' && mediakit?.profile?.handle) {
      setValue(`${window.location.origin}/${mediakit?.profile?.handle}`);
    }
  }, [mediakit?.profile?.handle]);

  const copyIdRef = React.useRef<any>(null);

  const [copied, setCopied] = React.useState(false);

  const onCopyClick = () => {
    setCopied(true);
    cleanCopyId();

    copyIdRef.current = setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  const cleanCopyId = () => {
    if (copyIdRef.current) {
      clearTimeout(copyIdRef.current);
    }
  };

  const onOpenChange = (open: boolean) => {
    if (open) {
      dispatch(onOpenShareModal());
    } else {
      dispatch(onCloseShareModal());
    }
  };

  const afterOpenChange = (open: boolean) => {
    if (open) {
      try {
        posthog?.capture(PosthogEvents.ShareClicked);
      } catch (e) {
        console.error('Posthog error:', e);
      }
    }
  };

  const onOpen = () => dispatch(onOpenShareModal());

  const icon = scrolled ? null : (
    <ShareAltOutlined style={{ verticalAlign: 'middle' }} />
  );

  const disabled = enableRes.isLoading || disableRes.isLoading || !isPublic;

  const content = (
    <Form style={{ width: 350 }} component="div" layout="vertical">
      <Form.Item
        label="General Access"
        style={{ marginBottom: 40, marginTop: 24 }}
        help={
          mediakit.profile.status === MediaKitStatus.PUBLIC
            ? 'Anyone with the link can view your media kit.'
            : 'No one can view your media kit.'
        }
      >
        <Select
          size="large"
          options={options}
          value={mediakit.profile.status}
          loading={enableRes.isLoading || disableRes.isLoading}
          onChange={(value) => {
            if (value === MediaKitStatus.PUBLIC) {
              enable(slug);
            } else {
              disable(slug);
            }
          }}
        />
      </Form.Item>

      <StyledText
        copyable={
          disabled
            ? false
            : {
                icon: <></>,
                text: value,
                onCopy: () => onCopyClick(),
              }
        }
      >
        <Button
          block
          size="large"
          type="primary"
          disabled={disabled}
          icon={<LinkOutlined />}
        >
          {copied ? 'Link Copied!' : 'Copy Link'}
        </Button>
      </StyledText>
    </Form>
  );

  return matches ? (
    <>
      {showSkeleton ? (
        <ButtonSkeleton />
      ) : (
        <Button
          icon={icon}
          size="large"
          onClick={onOpen}
          id="share-button"
          type={scrolled ? 'primary' : void 0}
          style={{ borderRadius: scrolled ? 99 : void 0 }}
        >
          {scrolled ? 'Share' : !matches && 'Share'}
        </Button>
      )}
      <MkitDrawer
        size="large"
        title="Share"
        height="35vh"
        placement="bottom"
        paddingXS="1.6rem"
        open={modalIsOpen}
        styles={drawerStyles}
        mkitTheme={drawerTheme}
        afterOpenChange={afterOpenChange}
        classNames={{ wrapper: 'drawer-wrapper' }}
        onClose={() => dispatch(onCloseShareModal())}
      >
        <Form component="div" layout="vertical">
          <Form.Item
            style={{ marginBottom: 40, marginTop: 24 }}
            label={<span style={{ color: '#FFFFFFE0' }}>General Access</span>}
            help={
              mediakit.profile.status === MediaKitStatus.PUBLIC ? (
                <span style={{ color: '#FFFFFFE0' }}>
                  Anyone with the link can view your media kit.
                </span>
              ) : (
                <span style={{ color: '#FFFFFFE0' }}>
                  No one can view your media kit.
                </span>
              )
            }
          >
            <Select
              size="large"
              options={[
                {
                  label: 'Anyone with the link can view',
                  value: MediaKitStatus.PUBLIC,
                },
                {
                  label: 'Private Media Kit',
                  value: MediaKitStatus.DISABLED,
                },
              ]}
              value={mediakit.profile.status}
              loading={enableRes.isLoading || disableRes.isLoading}
              onChange={(value) => {
                if (value === MediaKitStatus.PUBLIC) {
                  enable(slug);
                } else {
                  disable(slug);
                }
              }}
            />
          </Form.Item>

          <StyledText
            copyable={
              disabled
                ? false
                : {
                    icon: <></>,
                    text: value,
                    onCopy: () => onCopyClick(),
                  }
            }
          >
            <Button
              block
              size="large"
              type="primary"
              disabled={disabled}
              icon={<LinkOutlined />}
              style={{
                backgroundColor: disabled ? '#D3D3D3' : void 0,
              }}
            >
              {copied ? 'Link Copied!' : 'Copy Link'}
            </Button>
          </StyledText>
        </Form>
      </MkitDrawer>
    </>
  ) : (
    <>
      {showSkeleton ? (
        <ButtonSkeleton />
      ) : (
        <Popover
          title="Share"
          trigger="click"
          content={content}
          open={modalIsOpen}
          placement="bottomRight"
          onOpenChange={onOpenChange}
          afterOpenChange={afterOpenChange}
        >
          <Button
            icon={icon}
            size="large"
            id="share-button"
            type={scrolled ? 'primary' : void 0}
            style={{ borderRadius: scrolled ? 99 : void 0 }}
          >
            {scrolled ? 'Share' : !matches && 'Share'}
          </Button>
        </Popover>
      )}
    </>
  );
};
