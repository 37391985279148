import { ReduxState } from '@shared/redux';
import { createDraftSafeSelector } from '@reduxjs/toolkit';

export const selectInstagramCalculatorsSelf = (state: ReduxState) =>
  state.instagramCalculators;

export const selectInstagramEngagement = createDraftSafeSelector(
  selectInstagramCalculatorsSelf,
  (state) => state.engagement,
);

export const selectInstagramEngagementData = createDraftSafeSelector(
  selectInstagramEngagement,
  (state) => state.data,
);

export const selectInstagramEngagementStatus = createDraftSafeSelector(
  selectInstagramEngagement,
  (state) => state.status,
);

export const selectInstagramFakeFollowers = createDraftSafeSelector(
  selectInstagramCalculatorsSelf,
  (state) => state.fakeFollowers,
);

export const selectInstagramFakeFollowersData = createDraftSafeSelector(
  selectInstagramFakeFollowers,
  (state) => state.data,
);

export const selectInstagramFakeFollowersStatus = createDraftSafeSelector(
  selectInstagramFakeFollowers,
  (state) => state.status,
);
