import React from 'react';
import styled from '@emotion/styled';
import { Button, Typography } from 'antd';
import { ProBadge } from '@shared/ui/pro-badge';
import { componentsMap } from './componentsMap';
import { DemoCardSize } from '@shared/constants';
import { useUpgradeModal } from '@shared/hooks/useUpgradeModal';
import { useTiktokRedirectMutation } from '@shared/services/tiktok';
import { SocialComponentsContainer } from '@shared/ui/demo-components-container';
import {
  useSelector,
  selectTikTokUsername,
  selectAuthUserLinkedAccounts,
  selectTikTokAccountComponents,
} from '@shared/redux';

const wrapperStyle: React.CSSProperties = {
  width: 'fit-content',
  position: 'relative',
  display: 'inline-flex',
};

export const TikTokAccountGroup: React.FC<{
  onAddItem: (e: React.MouseEvent<HTMLDivElement>) => void;
}> = ({ onAddItem }) => {
  const tikTokUsername = useSelector(selectTikTokUsername);

  return !!tikTokUsername ? <Body onAddItem={onAddItem} /> : null;
};

const ConnectTikTokAccountCard = styled('div')(() => ({
  gap: '0.8rem',
  display: 'flex',
  padding: '1.6rem',
  borderRadius: '0.8rem',
  marginBottom: '3.2rem',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  backgroundColor: 'rgba(255, 255, 255, 0.10)',
  border: '1px solid rgba(255, 255, 255, 0.05)',
}));

const ConnectTikTokAccount: React.FC = () => {
  const [loading, setLoading] = React.useState(false);
  const [tiktokRedirect] = useTiktokRedirectMutation();
  const linkedAccounts = useSelector(selectAuthUserLinkedAccounts);

  const onClick = async () => {
    setLoading(true);

    try {
      const res = await tiktokRedirect().unwrap();
      if (res.data.target_url) {
        window.location.replace(res.data.target_url);
      }
    } catch (e) {
      console.error(e);
    }

    setLoading(false);
  };

  return !linkedAccounts?.tiktok ? (
    <ConnectTikTokAccountCard>
      <Typography.Text style={{ color: 'rgba(255, 255, 255, 0.88)' }}>
        To receive more accurate data, please connect your TikTok account.
      </Typography.Text>
      <Button
        danger
        block
        size="large"
        type="primary"
        loading={loading}
        onClick={onClick}
      >
        Connect TikTok
      </Button>
    </ConnectTikTokAccountCard>
  ) : null;
};

const Body: React.FC<{
  onAddItem: (e: React.MouseEvent<HTMLDivElement>) => void;
}> = ({ onAddItem }) => {
  const { handleOpen } = useUpgradeModal();
  const components = useSelector(selectTikTokAccountComponents);
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    containerRef.current?.scrollIntoView();
  }, []);

  return (
    <div>
      <div ref={containerRef}>
        <ConnectTikTokAccount />
      </div>
      <SocialComponentsContainer>
        {components.map((componentData, index) => {
          const Card = componentsMap[componentData.name];
          const isPro = componentData.access === 'pro';

          const click = isPro
            ? () => {
                handleOpen('component', {
                  seeAll: true,
                  from: componentData.name,
                });
              }
            : onAddItem;

          return !!Card ? (
            <div key={index} style={wrapperStyle}>
              <ProBadge isPro={isPro} />
              <Card
                onAddItem={click}
                data={componentData}
                size={DemoCardSize.large}
              />
            </div>
          ) : null;
        })}
      </SocialComponentsContainer>
    </div>
  );
};
